$baseUrl: '/__app__/id/stage';
@use '@smart/web-components/dist/scss/variables' as *;

.header {
  height: 76px;
  display: flex;
  justify-content: center;
  background-color: white;

  &__link {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  &__logo {
    height: 100%;
    color: black;
  }
}
