$baseUrl: '/__app__/id/stage';
@use '@smart/web-components/dist/scss/variables' as *;

.market-available {
  text-align: center;

  &__heading_wrapper {
    display: flex;
    justify-content: center;
    padding-bottom: var(--spacing-500);
  }

  &__bodytext_wrapper {
    padding-bottom: var(--spacing-600);
  }

  &__warning_wrapper {
    display: flex;
    justify-content: center;
  }

  &__button_wrapper {
    padding-bottom: var(--spacing-200);
  }

  &__button,
  &__button-ghost {
    width: 100%;
    @include breakpoint(m) {
      width: 70%;
    }
  }
}
